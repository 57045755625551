import React, { useContext, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Redirect, useHistory } from "react-router-dom";
import { Routes } from "../../configuration";
import ServicesContext, { Context } from "../../services/ServicesContext";
import { Box } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../configuration";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    cursor: "pointer"
  },
  errorBox: {
    padding: "13px 18px",
    width: "100%",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    marginBottom: 8,
    backgroundColor: '#ff5353',
  },
  errorText: {
    color: "white",
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [error, setError] = useState<string>();
  const accountService = useContext<Context>(ServicesContext).accountService;

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const isDisabled = (): boolean => {
    if (email && password) {
      return false;
    }
    return true;
  };

  const handleSubmit = (captchaResponse: string | null) => {
    if (recaptchaRef && recaptchaRef.current)
      recaptchaRef.current.reset();

    (async () => {
      const result = await accountService.signIn(email!, password!);
      if (result !== null) {
        setError(result);
        return;
      }
  
      history.push(Routes.list);
    })();
  };

  const redirectToSignUp = () => {
    history.push(Routes.signUp);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptchaKey as string}
        onChange={handleSubmit}
        size="invisible"
      />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            if (recaptchaRef && recaptchaRef.current)
              recaptchaRef.current.execute();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isDisabled()}
          >
            Sign In
          </Button>
          <Grid container justifyContent="center">
            {error &&
              <Box className={classes.errorBox}>
                <Typography align="center" className={classes.errorText} variant="overline">
                  {error}
                </Typography>
              </Box>
            }
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" onClick={redirectToSignUp} className={classes.link}>
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
