import { makeAutoObservable } from "mobx";
import { Database, Parameter, Query, Version } from "../../components/query/dtoInterfaces";
import api from "../../dataProvider/api";
import convertToParametersArray from "../../helpers/convertToParametersArray";

export const databaseIdName = "Database";

export default class QueryService {
  constructor () {
    makeAutoObservable(this);
    this._queriesStore = new Array<Query>();
  }

  private _currentQuery?: Query;
  private _currentQueryVersion?: Version;
  private _currentDatabase?: Database;
  private _allDatabases? : Array<Database>;
  private _queriesStore: Array<Query>;
  private _allQueryVersionParameters: Array<Array<Parameter>> = new Array<Array<Parameter>>();

  public get allDatabases () {
    return this._allDatabases;
  }

  public get currentDatabase () {
    return this._currentDatabase;
  }

  public get currentQuery () {
    return this._currentQuery;
  }

  public get currentQueryVersion () {
    return this._currentQueryVersion;
  }

  public get queriesStore () {
    return this._queriesStore;
  }

  public set queriesStore (queries: Array<Query>) {
    this._queriesStore = queries;
  }

  public set allQueryVersionParameters (parameters: Array<Array<Parameter>>) {
    this._allQueryVersionParameters = parameters;
  }

  public get allQueryVersionParameters () {
    return this._allQueryVersionParameters;
  }

  setNewQuery = (query: Query) => {
    this._currentQuery = query;
    this._currentQueryVersion = query?.queryVersions?.sort((a,b) => (a.dateCreate < b.dateCreate) ? 1 : ((b.dateCreate < a.dateCreate) ? -1 : 0))[0];
  };

  clearCurrentQuery = () => {
    this._currentQuery = undefined;
    this._currentQueryVersion = undefined;
  };

  selectQueryVersion = (queryVersion: Version) => {
    this._currentQueryVersion = queryVersion;
  };

  setNewQueryVersion = (queryVersion: Version) => {
    this._currentQueryVersion = queryVersion;
    if (this._currentQueryVersion.parameters === undefined)
      this._currentQueryVersion.parameters = [];
  };

  setNewCurrentVersion = (id: number) => {
    const ver = this._currentQuery?.queryVersions.filter(qv => qv.id === id)[0];
    if (ver)
      this._currentQueryVersion = ver;
  }

  trySetCurrentDatabase = (id: number) => {
    if (!this._allDatabases)
      return;

    const database = this._allDatabases.find(d => d.id === id);
    if (!database)
      return;

    this._currentDatabase = database;
    localStorage.setItem(databaseIdName, database.id.toString());
    this.clearCurrentQuery();
    
    return database;
  }

  clearDatabase = () => {
    this._currentDatabase = undefined;
    this._allDatabases = undefined; 
  }

  loadAllDatabases = async () => {
    const databases = await api.getAllDatabases();
    this._allDatabases = databases;
  }

  updateCurrentQuery = async () => {
    const query = await api.getQueryById(this._currentQuery!.id);
    this.currentQuery!.lastChange = query.lastChange;
    this.currentQuery!.lastExecution = query.lastExecution;
    this.currentQuery!.lastExport = query.lastExport;
  }
  
  setCurrentQueryParameters = async (parameters: Array<Parameter>) => {
    if (this.currentQueryVersion){
      let convertedParameters = convertToParametersArray(parameters);
      this.allQueryVersionParameters = convertedParameters;
    }
  }

  changeCurrentQueryParameters = (increment: number) => {
    if (this.allQueryVersionParameters?.length && this.currentQueryVersion){
      let currentParametersIndex = this.allQueryVersionParameters.findIndex(p => JSON.stringify(p) === JSON.stringify(this.currentQueryVersion?.parameters));
      if (this.allQueryVersionParameters[currentParametersIndex + increment])
        this.currentQueryVersion.parameters = this.allQueryVersionParameters[currentParametersIndex + increment];
      else{
        if (increment > 0)
          this.currentQueryVersion.parameters = this.allQueryVersionParameters[0];
        else
          this.currentQueryVersion.parameters = this.allQueryVersionParameters[this.allQueryVersionParameters.length - 1];
      }
    }
  }
}
